import { provideIntl } from '@alife/universal-intl';
import { Provider } from 'mobx-react';
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { intlName } from './constants';
import App from './container/app';
import imCoreUtil from './im-core';
import PluginLoader from './im-core/plugin-loader';
import { I18nMsg as messagesAIO } from './messages/index';
import FallingDownPlugin from './plugins/falling-down';
import Pluginfy from './plugins/pluginfiy';
import SmartCardStore from './stores/smart-card';
import Tracking from './utils/tracking';
import './utils/traffic';

import './index.scss';
if (!globalThis.exports) {
  globalThis.exports = module.exports || {};
}

class Chat {
  init(config) {
    if (typeof config === 'undefined') {
      Tracking.trackError({
        api: 'buyer/init',
        type: 'CONFIG_MISSING',
      });
      return;
    }
    this.instance = imCoreUtil.setConfig(config).init().getInstance();
    const { store } = this.instance;
    store.sdkConfig = config;
    // load plugins
    PluginLoader.getLoader(store).loadPlugins([Pluginfy(), FallingDownPlugin()]);
    if (!config.disabled) {
      this.setUpIntl(config);
      window._imSDKconfig = config;
      return window._chat;
    }
  }

  setUpIntl = (config) => {
    const intlParams = {
      locale: config.lang || 'en',
      messagesAIO,
      name: intlName,
    };
    provideIntl(intlParams);
  };

  checkInit() {
    if (!this.instance) {
      throw new Error('IM not inited');
    }
  }

  createSession(accountId, fromCode, skuId, itemId) {
    this.checkInit();
    const { store } = this.instance;
    store.openView(true);
    store.createSession(accountId, 2, fromCode).then(() => {
      if (skuId && itemId) {
        this.smartCardStore.loadSmartCard({
          skuId,
          itemId,
          sellerId: accountId,
        });
      }
    });
  }

  openWithSession = ({ accountId, fromCode, skuId, itemId }) => {
    this.createSession(accountId, fromCode, skuId, itemId);
  };

  render(containerId) {
    const smartCardStore = new SmartCardStore(this.instance.store);
    this.smartCardStore = smartCardStore || 'IMTEST-Buyer';

    ReactDOM.render(
      <Provider Store={this.instance.store} SmartCardStore={smartCardStore}>
        <div className="im">
          <App />
        </div>
      </Provider>,
      // <div id='aaaaa-bbbbb'>aaaaaaaa</div>,
      document.getElementById(containerId),
    );

    // const container = document.getElementById(containerId);
    // if (container) {
    //   const root = createRoot(container);
    //   root.render(
    //     <Provider Store={this.instance.store} SmartCardStore={smartCardStore}>
    //     <div className="im">
    //       <App />
    //     </div>
    //   </Provider>
    //   );
    // }
  }
}

window._chat = new Chat();

// HACK:(start um loading)
((w, d, t) => {
  var s = d.createElement(t),
    m = d.getElementsByTagName(t)[0];
  s.async = 1;
  s.src = 'https://g.alicdn.com/sd/pointman/js/pt2.js?_=' + Math.floor(new Date().getTime() / 36e5);
  m.parentNode.insertBefore(s, m);

  w._pointman_q = w._pointman_q || [];
  _pointman_q.push([
    'um',
    (umx) => {
      umx.init({
        timeout: 3000,
        serviceLocation: 'lazada',
        appName: 'lzd-sc-im-center',
      });
    },
  ]);
})(window, document, 'script');
// END HACK

function loadJS(src, fn) {
  var tn = 'script';
  var m = document.getElementsByTagName(tn)[0];
  var s = document.createElement(tn);
  s.async = 1;
  s.src = src;
  s.crossOrigin = true;
  if (fn) {
    var loaded = false;
    s.onload = s.onreadystatechange = () => {
      if (!loaded && (!s.readyState || /loaded|complete/.test(s.readyState))) {
        s.onload = s.onreadystatechange = null;
        loaded = true;
        fn();
      }
    };
  }
  s.onerror = () => {};
  m.parentNode.insertBefore(s, m);
}
// loadJS("https://g.alicdn.com/??/AWSC/AWSC/awsc.js,/sd/baxia-entry/baxiaCommon.js",function(){
//   if (!baxiaCommon) return;
//   const preCheck = window.__baxia__ && window.__baxia__.options && window.__baxia__.options.checkApiPath;
//   baxiaCommon.init && baxiaCommon.init({
//     appendTo: 'header',
//     umOptions: {
//       serviceLocation: 'lazada'
//     },
//     checkApiPath: function (url) {
//       let result = url.indexOf('mtop.im.receiver.web.buyer.immessage.sendimmessage') > -1;
//       if (typeof preCheck === 'function') result = preCheck(url) || result;
//       return result;
//     }
//   });
// })
