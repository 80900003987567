import withI18n from '@ali/lzdmod-intl-utils';
import { Input } from '@alifd/next';
import { defineMessages } from '@alife/universal-intl';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
// Fix require env https://img.alicdn.com/imgextra/i4/O1CN01m1Ibte1UwbfZUoWUa_!!6000000002582-0-tps-1271-473.jpg
import TextareaAutosize from 'react-textarea-autosize';
import Balloon from '../../components/balloon';
import FileUpload from '../../components/file-upload';
import { intlName } from '../../constants';
import bem from '../../utils/bem';
import Emotions from '../emotions';
import './index.scss';

import { IconPlane } from './plane';
import { IconPlus } from './plus';
import { IconSmile } from './smile';

const messages = defineMessages({
  placeholder: {
    id: 'im.messageView.placeholder',
    defaultMessage: 'Enter your message',
  },
});

class MessageViewField extends React.Component {
  static propTypes = {
    onFilesSelect: PropTypes.func.isRequired,
    onSubmitText: PropTypes.func.isRequired,
    onSubmitEmoji: PropTypes.func.isRequired,
    setDraft: PropTypes.func.isRequired,
    draft: PropTypes.string,
    activeSession: PropTypes.shape({}),
  };

  static defaultProps = {
    draft: '',
    activeSession: null,
  };

  state = {
    hasTip: false,
    tipText: '',
  };

  constructor(props) {
    super(props);

    this.onFilesSelect = this.onFilesSelect.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._inputFileField = null;
    this._smileBalloonMgr = null;

    this.fielMgr = {
      clearInputFile: () => {
        this._inputFileField.value = null;
      },
    };
  }

  componentDidMount() {
    this._inputField && this._inputField.focus();
  }

  componentWillUpdate(nextProps) {
    const { activeSession, draft } = nextProps;
    if (!this._inputField) {
      return;
    }
    if (this.props.activeSession !== activeSession) {
      this._inputField.focus();
      this._inputField.value = draft;
    }
  }

  onFilesSelect(files) {
    const { onFilesSelect } = this.props;
    onFilesSelect(files);
  }

  onSubmit(event) {
    event.preventDefault();
    const { onSubmitText, inputMessage, updateInputMessage } = this.props;
    if (this.canSubmit(inputMessage)) {
      onSubmitText && onSubmitText(inputMessage.trim());
      updateInputMessage('');
    } else if (inputMessage.length > 0) {
      this.setState({
        hasTip: true,
        tipText: 'Text length should be less than 2000',
      });
      setTimeout(() => {
        this.setState({
          hasTip: false,
          tipText: '',
        });
      }, 3000);
    }
  }

  onChange = (e) => {
    const { setDraft, updateInputMessage } = this.props;
    const { value } = e.target;
    setDraft && setDraft(value);
    updateInputMessage(value);
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onSubmit(e);
    }
  };

  canSubmit(inputText) {
    const text = inputText.trim();
    return text.length > 0 && text.length <= 2000;
  }

  render() {
    const BEM = bem('message-view-field', this.props);
    const { hasTip, tipText } = this.state;
    const { inputMessage } = this.props;
    return (
      <form onSubmit={this.onSubmit} className={BEM.block()}>
        <FileUpload
          trigger={<IconPlus />}
          onFilesSelect={this.onFilesSelect}
          className={BEM.element('upload')}
          accept="image/*"
        />
        <div className={BEM.element('input-block')}>
          <TextareaAutosize
            value={inputMessage}
            // maxRows={5}
            className="message-fileds__autosize"
            style={{ border: 'none', padding: '0' }}
            maxLength={180}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            // ref={(ref) => {
            //   this._inputField = ref ? ref.textarea : this._inputField;
            // }}
          />
          <Balloon
            className="emoji-face"
            getStateMgr={(mgr) => {
              this._smileBalloonMgr = mgr;
            }}
            trigger={<IconSmile className="clickable" />}
          >
            <Emotions
              onSelectItem={(data) => {
                this.props.onSubmitEmoji(data);
                this._smileBalloonMgr.hide();
              }}
            />
          </Balloon>
          {hasTip && <div className="toast-tip-message">{tipText}</div>}
        </div>
        <button type="submit" className={BEM.element('send')}>
          <IconPlane
            className={classnames(
              'icon-plane',
              this._inputField && this.canSubmit(this._inputField.value) ? 'enable' : 'disable',
            )}
          />
        </button>
      </form>
    );
  }
}

export default withI18n(messages, intlName)(MessageViewField);
